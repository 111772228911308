import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter, NavLink } from 'react-router-dom';
import Header from '../components/global/header';
import BookingSheet from '../components/teeTime/bookingSheet';
import MyBooking from '../components/teeTime/myBooking';
import { getAuthState } from '../assets/scripts/login-util';

import swal from "sweetalert2";
import { io } from "socket.io-client";

const Master = (props) => {
    const path =  window.location.hostname === 'localhost'?'http://localhost:8080':( 
        window.location.hostname.includes('cluster') ? `${window.location.protocol}//cluster-admin.golfez.in`:(`${window.location.protocol}//gbgc-admin.golfez.in`));
    
    const [socketMaster, setSocketMaster] = useState();

    useEffect(() => {                     
        if(!!props?.auth) initSocketConnection();        
    },[props.auth]);

    //Connect's to intended socket io server -->
    const initSocketConnection = ()=> {
        try {    
            const token  = getAuthState('token');                
            const socket = io(path, { transports: ['websocket'], withCredentials:true } );		
            // const socket = io(path, { transports: ['polling','websocket'], upgrade: true , withCredentials: true});		
                
            //Emit user connected event to server -->
            socket.on('connect', ()=> socket.emit('user-connected', { socketID: socket.id, token }));

            setSocketMaster(socket);           
        } catch (err) {                    
            swal.fire({ icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                text: 'Please Reload...', title: "Something went wrong..!",
            }); 
        }
    }

    return (  
        <>
            <Header/>
            <div className="container padding-t15">
                {/* Tab  */}
                <div id="pnlMyBooking" className="row margin-b5">
                    <div className="col-xs-6 padding-lr5">
                        <NavLink to="/tee-booking"  id="btnBook" activeClassName="btn-success" className="btn btn-default btn-block padding-lr10">Book Tee Time</NavLink>
                     
                    </div>
                    <div className="col-xs-6 padding-lr5">                      
                        <NavLink  to="/my-booking" id="btnMy" activeClassName="btn-success"  className="btn btn-default btn-block padding-lr10">My Bookings</NavLink>
                    </div>
                </div>

                <Switch>
                    <Route path="/tee-booking">
                        <BookingSheet socketMaster = {socketMaster} />
                    </Route>
                    <Route path="/my-booking">
                        <MyBooking />
                    </Route>

                    <Redirect to="/tee-booking" />
                </Switch>
                
            </div>
        </>
    );
};

export default withRouter(Master);