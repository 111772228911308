import React, { useState, useEffect } from 'react';
import bannerLogo from '../../assets/images/GBGC_Login_logo.png';
import poweredBy from '../../assets/images/GolfEZ-powered_PNG.png';
import { NavLink, useHistory } from 'react-router-dom';
import { changePassword, sendRegistrationOTP } from '../api/loginAPI';
import Swal from 'sweetalert2';

const Reset = (props) => {

    const [labelText,         setLabelText]         = useState('');
    const [sessionId,         setSessionId]         = useState(0);
    const [userID,            setUserID]            = useState(0);
    const [mobile,            setMobile]            = useState('');
    const [code,              setCode]              = useState('');
    const [password,          setPassword]          = useState('');
    const [confirmPassword,   setConfirmPassword]   = useState('');
    const [showPassword,      setShowPassword]      = useState(false);
    const history 				                              = useHistory();

    useEffect(() => {
        setSessionId($.session.get('Code'));
        setMobile($.session.get('Mobile'));
        setUserID($.session.get('UserID')); 
        validationAttachments();      
    }, []);

    useEffect(() => {
        setLabelText('We have sent an OTP to ' + mobile + ' Please enter it to continue.');
    }, [mobile]);

    const validationAttachments = () => {
        $('#ControlHolder').bootstrapValidator({
            fields: {
                txtCode: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter OTP'
                        },
                        numeric: {
                            message: 'Number is required'
                        }
                    }
                },
                txtPassword: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter password'
                        },
                        stringLength: {
                            min: 5,
                            message: 'Password should be at least 5 characters long'
                        }
                    }
                },
                txtConfirmPassword: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter password'
                        },
                        identical: {
                            field: 'txtPassword',
                            message: 'Confirm password missmatch with password'
                        }
                    }
                }
            }
        });
    }

    const resetHandler = async (e) => {
        e.preventDefault();

        try {
            Swal.fire({
                titleText           : 'Loading',
                text                : 'Please Wait...',
                showConfirmButton   : false,
                allowOutsideClick   : false,
                onOpen: () => {
                    Swal.showLoading();
                }
            });
            await changePassword(userID, password, sessionId, code);
            Swal.close();
            Swal.fire({
                icon: 'success',
                titleText: 'Password updated successfully. Please login with updated password',
                showConfirmButton: false,
                timer: 1500,
            });
            history.push('/login');
        } catch (err) {
            Swal.close();
            Swal.fire({
                icon              : "error",
                titleText         : "Error!",
                text              : err.message
              })
            return; 
        }
    };

    const resendHandler = async (e) => {
        e.preventDefault();

        try {
            Swal.fire({
                titleText           : 'Loading',
                text                : 'Please Wait...',
                showConfirmButton   : false,
                allowOutsideClick   : false,
                onOpen: () => {
                    Swal.showLoading();
                }
            });
            const response = await sendRegistrationOTP(mobile);
            $.session.set('Code', response.Session);
            setSessionId(response.Session);
            Swal.close();
            Swal.fire({
                icon: 'success',
                titleText: 'OTP send to ' + mobile,
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            Swal.close();
            Swal.fire({
                icon              : "error",
                titleText         : "Error!",
                text              : err.message
              })
            return; 
        }
    };

    return (
        <div className="container margin-t50">
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <div className="login-panel">
                        <NavLink to={'/login'}>
                            <img className="img-responsive img-login" src={bannerLogo} alt="" />
                        </NavLink>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">Reset Password</h3>
                        </div>
                        <div className="panel-body">
                            <div id="resetForm" role="form">
                                <div id="ControlHolder">
                                    <span id="lblMobile" className="lbl">{labelText}</span><br /><br />
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => setCode(target.value)}
                                            value={ code || '' }
                                            id="txtCode" 
                                            name="txtCode" 
                                            className="form-control" 
                                            type="text" 
                                            placeholder="Code" 
                                            autoFocus="autofocus" 
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => setPassword(target.value)}
                                            value={ password || '' }
                                            id="txtPassword" 
                                            name="txtPassword" 
                                            className="form-control" 
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="New Password" 
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => setConfirmPassword(target.value)}
                                            value={ confirmPassword || '' }
                                            id="txtConfirmPassword" 
                                            name="txtConfirmPassword" 
                                            className="form-control" 
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Confirm Password" 
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="inline">
                                            <input
                                                defaultChecked={showPassword ? 'checked' : ''}
                                                onChange={(event) => setShowPassword(event.target.checked)} 
                                                type="checkbox" 
                                                className="ace" 
                                                id="chkShowPassword" 
                                                name="chkShowPassword"
                                            />
                                            <span className="lbl">Show Password</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="clearfix form-actions">
                                    <button 
                                        type="button" 
                                        id="btnReset" 
                                        name="btnReset" 
                                        onClick={resetHandler}
                                        className="btn btn-lg btn-success btn-block">Reset
                                    </button><br />
                                    <a href='#' onClick={resendHandler} className="lbl text-center">Resend OTP</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="container-power-login">                        
                        <a className="power" href='https://golfezapp.com/' target="_blank">
                             <img src={poweredBy} alt=""/>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    )

}

export default Reset;